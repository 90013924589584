import Script from 'next/script';

interface WootWindow extends Window {
  chatwootSDK: {
    run: (config: { websiteToken: string; baseUrl: string }) => void;
  };
}

export const ChatWidget = () => {
  return (
    <Script
      src="https://app.chatwoot.com/packs/js/sdk.js"
      strategy="lazyOnload"
      defer
      async
      onLoad={() => {
        (window as unknown as WootWindow).chatwootSDK.run({
          websiteToken: 'WVfN6riXzi7JLy7PpvMbS8ia',
          baseUrl: 'https://app.chatwoot.com',
        });
      }}
    />
  );
};
